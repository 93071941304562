import React from "react"
import { Switch, Redirect } from "react-router-dom"

import { RouteWithLayout } from "./components"
import { Minimal as MinimalLayout, Main as MainLayout } from "./layouts"

import {
  NotFound as NotFoundView,
  Login as LoginView,
  Home as HomeView,
} from "./views"

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/login" />
      <RouteWithLayout
        component={LoginView}
        exact
        layout={MinimalLayout}
        path="/login"
      />
      <RouteWithLayout
        component={HomeView}
        exact
        layout={MainLayout}
        path="/home"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  )
}

export default Routes
