import React, { useState, useEffect } from "react"
import { Sidebar, Topbar } from "./components"
import { Grid, Layout, Spin } from "antd"

const Main = props => {
  const { children } = props
  const { Header, Sider, Content } = Layout
  const { useBreakpoint } = Grid
  const defaultCollapseWidth = 80

  const [isCollapsed, setIsCollapsed] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [collapseWidth, setCollapseWidth] = useState(defaultCollapseWidth)

  const breakpoint = useBreakpoint()
  const isHamburger = !breakpoint.lg

  const collapse = () => {
    setIsCollapsed(true)
    setCollapseWidth(0)
  }

  const expand = () => {
    setIsCollapsed(false)
    setCollapseWidth(defaultCollapseWidth)
  }

  const collapseToggle = () => {
    if (isCollapsed) {
      expand()
    } else {
      collapse()
    }
  }

  const showLoadingPageSpin = () => {
    setIsPageLoading(true)
  }

  const hideLoadingPageSpin = () => {
    setIsPageLoading(false)
  }

  const childrenWithProps = React.Children.map(children, element =>
    React.cloneElement(element, {
      showLoadingPageSpin: showLoadingPageSpin,
      hideLoadingPageSpin: hideLoadingPageSpin,
    }),
  )

  useEffect(() => {
    if (isHamburger) {
      collapse()
    } else {
      expand()
    }
  }, [isHamburger])

  return (
    <div className="flex flex-col w-full min-h-full">
      <Spin spinning={isPageLoading} style={{ maxHeight: "100vh" }}>
        <Layout className="min-h-full">
          {isHamburger ? (
            isCollapsed ? (
              <div></div>
            ) : (
              <div
                onClick={() => collapse()}
                className="absolute top-0 left-0 bg-black bg-opacity-50 w-full h-full z-50"
              ></div>
            )
          ) : (
            <div
              style={{
                flex: "0 0 200px",
                minWidth: "200px",
                maxWidth: "200px",
                overflow: "hidden",
              }}
            ></div>
          )}
          <Sider
            theme="light"
            collapsible
            collapsed={isCollapsed}
            collapsedWidth={collapseWidth}
            trigger={null}
            className="fixed top-0 left-0 h-full shadow z-50 border-r border-solid border-gray-300"
          >
            <Sidebar isHamburger={isHamburger} />
          </Sider>
          <Layout className="site-layout min-h-screen">
            <Header className="site-layout-sub-header-background p-0 shadow">
              <Topbar
                isHamburger={isHamburger}
                collapseToggle={collapseToggle}
              />
            </Header>
            <Content className="site-layout-background m-5 bg-gray-100 border-solid border border-gray-400 shadow">
              {childrenWithProps}
            </Content>
          </Layout>
        </Layout>
      </Spin>
    </div>
  )
}

export default Main
