import React from "react"
import { Divider, Menu, message, Typography } from "antd"
import { FundViewOutlined, LogoutOutlined } from "@ant-design/icons"
import { withRouter } from "react-router-dom"
import styled from "styled-components"
import * as Commons from "common/common"

const StyledMenu = styled(Menu)`
  border: none;
`

const Sidebar = props => {
  const { history } = props
  const isMountedRef = Commons.useIsMountedRef()
  const { Text } = Typography

  const logout = () => {
    Commons.axiosInstance
      .get(Commons.apiLogout)
      .then(response => {
        if (response.status === 200) {
          message.success(Commons.successLogoutMsg)
          history.push(Commons.loginURL)
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
        }
      })
  }

  const handleClick = event => {
    switch (event.key) {
      case "logout":
        logout()
        break
      case "event":
        history.push(Commons.rootURL)
        break
      default:
        break
    }
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex" style={{ marginTop: "1rem" }}>
        <img src="logo.png" alt="" className="mx-auto" />
      </div>
      <div className="relative flex text-center px-1">
        <Text style={{ fontSize: 16, marginTop: 20 }} className="mx-auto">
          車買取専門店
          <br />
          スマイル
        </Text>
      </div>
      <Divider />
      <div className="flex-grow flex-shrink">
        <StyledMenu onClick={handleClick} defaultSelectedKeys={["event"]}>
          <Menu.ItemGroup
            key="menuGroupTop"
            title="TOP"
            className="text-center"
          >
            <Menu.Item
              key="event"
              icon={<FundViewOutlined />}
              className="text-center"
            >
              車買取管理
            </Menu.Item>
          </Menu.ItemGroup>
        </StyledMenu>
      </div>
      <Divider style={{ margin: 0 }} />
      <StyledMenu onClick={handleClick}>
        <Menu.Item
          key="logout"
          icon={<LogoutOutlined />}
          className="text-center"
          danger="true"
        >
          ログアウト
        </Menu.Item>
      </StyledMenu>
    </div>
  )
}

export default withRouter(Sidebar)
