import React from "react"
import ReactDOM from "react-dom"
import { ConfigProvider } from "antd"
import jaJP from "antd/es/locale/ja_JP"

import * as serviceWorker from "./serviceWorker"
import App from "./App"

ReactDOM.render(
  <ConfigProvider locale={jaJP}>
    <App />
  </ConfigProvider>,
  document.getElementById("root"),
)

serviceWorker.unregister()
