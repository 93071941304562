import React, { useState, useEffect, useCallback } from "react"
import { withRouter } from "react-router-dom"
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  message,
  Popconfirm,
  Space,
  Table,
  Typography,
  Row,
} from "antd"
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import moment from "moment"
import "moment/locale/ja"
import { socket } from "common/socket"
import * as Commons from "common/common"

moment.locale("ja")

const Home = props => {
  const { history, showLoadingPageSpin, hideLoadingPageSpin } = props

  const isMountedRef = Commons.useIsMountedRef()
  const [pagination, setPagination] = useState({
    perPage: 20,
    page: 1,
    total: 0,
  })
  const [sales, setSales] = useState([])
  const [salesFilter, setSalesFilter] = useState({
    name: "",
    telephone: "",
    date: [],
  })

  const { RangePicker } = DatePicker
  const { Text } = Typography

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
    },
    {
      title: "お名前",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "電話番号",
      dataIndex: "telephone",
      align: "center",
    },
    {
      title: "作成日",
      dataIndex: "updatedAt",
      align: "center",
      render: (text, record) => moment(text).format("YYYY年M月D日"),
    },
    {
      title: "車画像－１",
      dataIndex: "carPic1",
      align: "center",
      render: (text, record) => (
        <Image width={60} src={Commons.mediaURL + text} />
      ),
    },
    {
      title: "車画像－２",
      dataIndex: "carPic2",
      align: "center",
      render: (text, record) => (
        <Image width={60} src={Commons.mediaURL + text} />
      ),
    },
    {
      title: "車検証の写真",
      dataIndex: "certificate",
      align: "center",
      render: (text, record) => (
        <div>
          <Image width={60} src={Commons.mediaURL + text} />
        </div>
      ),
    },
    {
      title: "行動",
      dataIndex: "action",
      align: "center",
      render: (text, record) => (
        <Popconfirm
          title="削除してもよろしいですか?"
          onConfirm={() => handleDelete(text)}
          okText="削除"
          cancelText="閉じる"
          okType="danger"
          icon={<QuestionCircleOutlined style={{ color: "red" }} />}
        >
          <Button
            style={{ marginTop: "10px" }}
            icon={<DeleteOutlined />}
            danger
          >
            削除
          </Button>
        </Popconfirm>
      ),
    },
  ]

  const fetchSalesData = useCallback(
    (param = {}) => {
      showLoadingPageSpin()

      Commons.axiosInstance
        .get(Commons.apiSales, param)
        .then(response => {
          if (
            response !== undefined &&
            response.data !== undefined &&
            isMountedRef.current
          ) {
            if (
              response.data.data !== undefined &&
              Object.keys(response.data.data).length !== 0
            ) {
              setSales(response.data.data)
            } else {
              setSales([])
            }

            if (
              response.data.search !== undefined &&
              response.data.search.perPage !== undefined &&
              response.data.search.page !== undefined &&
              response.data.search.total !== undefined
            ) {
              setPagination({
                perPage: response.data.search.perPage,
                page: response.data.search.page,
                total: response.data.search.total,
              })
            } else {
              setPagination({
                perPage: 20,
                page: 1,
                total: 0,
              })
            }
          }
        })
        .catch(error => {
          if (error.response.status === 403) {
            message.warning(Commons.errorSessionMsg)
            history.push(Commons.loginURL)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          if (isMountedRef.current) {
            hideLoadingPageSpin()
          }
        })
    },
    [isMountedRef, history, showLoadingPageSpin, hideLoadingPageSpin],
  )

  const handleDelete = id => {
    showLoadingPageSpin()

    const param = {
      data: {
        salesId: id,
      },
    }

    Commons.axiosInstance
      .delete(Commons.apiSales, param)
      .then(response => {
        if (response.status === 200) {
          message.success(Commons.successDeleteSalesMsg)
          handleFilter()
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          hideLoadingPageSpin()
        }
      })
  }

  const handleTableChange = (paginationData, filters, sorter) => {
    const paramData = {
      params: {
        name: salesFilter.name,
        tel: salesFilter.telephone,
        start:
          salesFilter.date !== null && salesFilter.date[0] !== undefined
            ? moment(salesFilter.date[0]).format("YYYY-MM-DD")
            : "",
        end:
          salesFilter.date !== null && salesFilter.date[1] !== undefined
            ? moment(salesFilter.date[1])
                .add(1, "days")
                .format("YYYY-MM-DD")
            : "",
        perPage: pagination.perPage,
        page: paginationData.current,
      },
    }

    fetchSalesData(paramData)
  }

  const handleFilter = values => {
    const paramData = {
      params: {
        name: salesFilter.name,
        tel: salesFilter.telephone,
        start:
          salesFilter.date !== null && salesFilter.date[0] !== undefined
            ? moment(salesFilter.date[0]).format("YYYY-MM-DD")
            : "",
        end:
          salesFilter.date !== null && salesFilter.date[1] !== undefined
            ? moment(salesFilter.date[1])
                .add(1, "days")
                .format("YYYY-MM-DD")
            : "",
        perPage: pagination.perPage,
        page: 1,
      },
    }

    fetchSalesData(paramData)
  }

  const handleChange = event => {
    switch (event.target.name) {
      case "salesNameFilter":
        setSalesFilter({ ...salesFilter, name: event.target.value })
        break
      case "salesTelephoneFilter":
        setSalesFilter({ ...salesFilter, telephone: event.target.value })
        break
      default:
    }
  }

  const handleDateChange = (dates, dateStrings) => {
    setSalesFilter({ ...salesFilter, date: dates })
  }

  useEffect(fetchSalesData, [])
  useEffect(() => {
    socket.on("salesUpdate", response => {
      if (response !== undefined && Object.keys(response).length !== 0) {
        let isUpdated = false

        setSales(
          sales.map(s => {
            if (response.salesId === s.salesId) {
              isUpdated = true

              return response
            } else {
              return s
            }
          }),
        )

        if (!isUpdated) setSales(sales => [response, ...sales])
      }
    })

    return () => {
      socket.off("salesUpdate")
    }
  }, [sales])

  return (
    <Card title="車買取管理" bordered={true} className="h-full">
      <Card
        title="検索フィルタ"
        bordered={true}
        type="inner"
        className="h-full mb-5"
        // headStyle={{
        //   color: "#FFF",
        //   backgroundColor: "#3dbaeb",
        // }}
        // style={{
        //   borderColor: "#3dbaeb",
        // }}
      >
        <Form
          name="salesFilter"
          layout="inline"
          onFinish={handleFilter}
          noValidate
        >
          <Row gutter={[8, 8]}>
            <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 8 }}>
              <Form.Item name="salesNameFilter" style={{ marginBottom: 5 }}>
                <Input
                  name="salesNameFilter"
                  placeholder="お名前"
                  onChange={handleChange}
                  value={salesFilter.name}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 8 }}>
              <Form.Item
                name="salesTelephoneFilter"
                style={{ marginBottom: 5 }}
              >
                <Input
                  name="salesTelephoneFilter"
                  placeholder="電話番号"
                  onChange={handleChange}
                  value={salesFilter.telephone}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 8 }}>
              <Form.Item name="salesDateFilter" style={{ marginBottom: 5 }}>
                <RangePicker
                  name="salesDateFilter"
                  onChange={handleDateChange}
                  value={salesFilter.date}
                  inputReadOnly
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ marginBottom: 5 }}>
            <Button type="primary" htmlType="submit">
              検索
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Divider />
      <Table
        bordered
        onChange={handleTableChange}
        columns={columns}
        scroll={{ x: true }}
        pagination={{
          current: pagination.page,
          pageSize: pagination.perPage,
          total: pagination.total,
          defaultCurrent: 1,
          defaultPageSize: 20,
        }}
        dataSource={
          sales
            ? sales.map(sale => {
                return {
                  key: sale.salesId,
                  id: sale.salesId,
                  name: sale.name,
                  telephone: sale.telephone,
                  updatedAt: sale.updatedAt,
                  carPic1: sale.carPic1,
                  carPic2: sale.carPic2,
                  certificate: sale.certPic,
                  action: sale.salesId,
                }
              })
            : []
        }
      />
    </Card>
  )
}

export default withRouter(Home)
