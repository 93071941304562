import { useEffect, useRef } from "react"
import axios from "axios"

/* BASE URL */
export const siteURL = "https://smileline.miraic.com"
// export const siteURL = "http://localhost:8080"
export const baseURL = siteURL + "/api"
export const mediaURL = "/imageuploads/"

/* REDIRECT URL */
export const loginURL = "/login"
export const rootURL = "/home"

/* BACKEND API URLS */
export const apiLogin = baseURL + "/login"
export const apiLogout = baseURL + "/logout"
export const apiSales = baseURL + "/sales"
export const apiCheckSession = baseURL + "/sess"

/* ALERT MESSAGES */
export const errorLoginMismatchMsg =
  "メールアドレスまたはパスワードが間違っています"
export const errorSystemMsg = "システムエラー"
export const errorSessionMsg = "もう一度ログインしてください"
export const error404Msg =
  "申し訳ありませんが、アクセスしたページは存在しません。"
export const successLoginMsg = "ログイン成功"
export const successLogoutMsg = "ログアウト成功"
export const successDeleteSalesMsg = "削除成功"

/* AXIOS CONFIG */
export const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: baseURL,
})

/* CHECK MOUNTED REACT */
export function useIsMountedRef() {
  const isMountedRef = useRef(null)

  useEffect(() => {
    isMountedRef.current = true
    return () => (isMountedRef.current = false)
  })

  return isMountedRef
}
