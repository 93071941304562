import React from "react"
import { Menu } from "antd"
import { MenuOutlined, UserOutlined } from "@ant-design/icons"

const Topbar = props => {
  const { isHamburger, collapseToggle } = props

  const handleClick = event => {
    switch (event.key) {
      case "menu":
        collapseToggle()
        break
      default:
        break
    }
  }

  return (
    <div>
      {isHamburger ? (
        <Menu
          mode="horizontal"
          onClick={handleClick}
          defaultSelectedKeys={["profile"]}
        >
          <Menu.Item
            className="float-left"
            key="menu"
            icon={<MenuOutlined />}
          ></Menu.Item>
          <Menu.Item
            className="float-right"
            key="profile"
            icon={<UserOutlined />}
          >
            管理者
          </Menu.Item>
        </Menu>
      ) : (
        <Menu mode="horizontal" defaultSelectedKeys={["profile"]}>
          <Menu.Item key="empty"></Menu.Item>
          <Menu.Item
            className="float-right"
            key="profile"
            icon={<UserOutlined />}
          >
            管理者
          </Menu.Item>
        </Menu>
      )}
    </div>
  )
}

export default Topbar
